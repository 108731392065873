@import '../../../themes/tokens';

.dealer--profile {
  .full-width {
    width: 100% !important;
  }

  .options--container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: $spacing-09;
    margin-top: $spacing-05;
  }

  .options--container__documents,
  .options--container__password {
    background: $ui-01;
    padding: $spacing-04;
    width: 100%;
  }

  .options--container__documents {
    margin-right: $spacing-02;
  }

  .options--container__documents--title,
  .options--container__password--title {
    color: $text-02;
  }

  .options--container__documents--link,
  .options--container__password--link {
    color: $interactive-01;
    cursor: pointer;

    a {
      color: $interactive-01;
      text-decoration: none;
    }
  }

  .profile--title {
    color: $text-01;
    margin-bottom: $spacing-05;
  }

  .profile--text {
    color: $text-01;
    margin-bottom: $spacing-05;
  }

  .profile--contact {
    color: $text-01;
    display: flex;
    flex-direction: row;
    margin-bottom: $spacing-08;
  }

  .profile--contact__labels {
    margin-right: $spacing-05;
  }

  .profile--contact__labels,
  .profile--contact__data {
    div {
      height: 1.2rem;
      line-height: 1.1rem;
    }
  }

  .marketplace--logo {
    div {
      height: 3rem;
    }
  }

  .profile--contact__data--email {
    color: $interactive-01;
  }

  .row-2 {
    display: flex;
    flex-direction: row;
  }

  .col-2 {
    display: flex;
    flex-direction: column;
  }

  input {
    background-color: $fp-light-grey;
    border: 0;
    outline: none;
    padding: $spacing-04 $spacing-05;
  }

  label {
    color: $disabled-02;
    margin-bottom: $spacing-03;
  }

  .personal--details,
  .personal--address {
    margin-bottom: $spacing-07;
  }

  .personal--details__heading,
  .personal--address__heading,
  .personal--contact__heading {
    margin-bottom: $spacing-05;
  }

  .personal--details .row-2:first-of-type {
    justify-content: space-between;
  }

  .personal--details svg {
    margin-right: $spacing-02;
    vertical-align: middle;
  }

  .personal--details .col-2,
  .personal--address .col-2,
  .personal--contact .col-2 {
    width: 50%;
  }

  .personal--details__title,
  .personal--details__dealer {
    color: $text-01;
  }

  .personal--details__dealer span {
    float: right;
  }

  .personal--details__dealer span:nth-child(2) {
    margin-right: $spacing-06;
  }

  .dealer-code {
    margin-top: $spacing-05;
  }

  .personal--address__street,
  .dealer-code {
    margin-bottom: $spacing-05;
    width: 100% !important;
  }

  .row-2 .col-2:first-child {
    margin-right: $spacing-07;
  }

  .panel--grey {
    background-color: #f5f5f5;
    border: 0;
    margin-bottom: $spacing-03;
    outline: none;
    padding: 0.01rem;

    img {
      max-height: 3rem;
    }
  }

  .personal--details__aal {
    padding: $spacing-04 $spacing-05;
  }

  .margin-top {
    margin-top: $spacing-05;
  }

  .margin-bottom {
    margin-bottom: $spacing-04;
  }
  .checkboxDisable {
    pointer-events: none;
  }

  .checkboxDisable .custom-checkbox {
    cursor: initial;
  }

  .checkboxDisable .custom-checkbox::before {
    background-color: transparent;
    content: '';
    height: 25px;
    pointer-events: none;
    position: absolute;
    width: 25px;
  }

  .checkboxDisable svg {
    color: #e1dcdc;
  }

  .checkbox-hand svg {
    cursor: pointer;
  }
}

@media screen and (max-width: 1024px) {
  .dealer--profile .row-2 .col-2:first-child {
    margin-right: 10px;
  }

  .fp-overlay .fp-overlay__content--container {
    padding: 20px 20px 40px !important;
  }
}
