@import '../../../styles/vars';
@import '../../../themes/tokens';

.time-slots-list {
  .time-slots-list__values {
    display: grid;
    grid-template-columns: auto auto auto;
    margin-top: $spacing-07;

    div {
      margin-top: $spacing-05;
    }

    .fp--label-01 {
      color: #6f6f6f;
    }

    .time-slot-day {
      vertical-align: middle;
    }

    .time-slot-period {
      margin-left: $spacing-07;
    }

    .time-slot-time {
      border: 1px solid $light-grey-border;
      border-radius: 6px;
      cursor: pointer;
      margin-left: $spacing-07;
      padding: $spacing-03 0;
      position: relative;
      text-align: center;
    }

    .time-slot--active {
      border: 2px solid $active-border;
    }

    .time-slot--disabled {
      color: $fp-dark-grey;
      cursor: not-allowed;
    }

    .time-slot__loading {
      position: absolute;
      right: -1.8rem;
      top: -0.8rem;
    }
  }
}
.checkboxDisable.timeslots--disabled {
  cursor: not-allowed;
}
.checkboxDisable.timeslots--disabled .time-slot-time {
  pointer-events: none;
  color: #e1dcdc;
}
