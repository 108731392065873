@import '../../../themes/tokens';

.dealer--timeslots {
  .dealer--timeslots__text {
    color: $text-01;
    margin-bottom: $spacing-07;
    margin-top: $spacing-05;
  }

  .dealer--timeslots__grid {
    display: grid;
    grid-gap: $spacing-07 $spacing-07;
    grid-template-columns: 6rem 6rem 6rem;
  }

  .dealer--timeslots__grid--header {
    color: $text-02;
    margin-bottom: $spacing-03;
  }

  .dealer--timeslots__grid--day,
  .dealer--timeslots__grid--morning,
  .dealer--timeslots__grid--afternoon {
    color: $text-01;
    position: relative;
  }

  .timeslots__loading {
    position: absolute;
    right: -1.5rem;
    top: 0;
  }

  .custom-checkbox {
    cursor: pointer;
    display: inline-block;
    margin-right: $spacing-03;
    vertical-align: top;
  }

  .timeslots--disabled {
    color: $fp-dark-grey;
  }

  .timeslots--disabled .custom-checkbox {
    cursor: not-allowed;
  }

  .checkboxDisable {
    pointer-events: none;
  }
  .checkboxDisable .custom-checkbox {
    cursor: not-allowed;
  }
  .checkboxDisable .custom-checkbox::before {
    background-color: transparent;
    content: '';
    height: 25px;
    pointer-events: none;
    position: absolute;
    width: 25px;
  }
  .checkboxDisable svg,
  .checkboxDisable .fp--body-short-01 {
    color: #e1dcdc;
  }
}
