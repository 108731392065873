@import '../../../../themes/tokens';

.vehicle-appointment {
  .vehicle-appointment--header {
    display: flex;
    justify-content: space-between;
    margin-top: $spacing-07;
  }

  .vehicle-appointment--header__left {
    color: $text-01;
  }

  .vehicle-appointment--header__right {
    color: $link-01;
    cursor: pointer;
    display: flex;
  }

  .vehicle-appointment--header__right > svg {
    margin-left: $spacing-02;
  }

  .vehicle-appointment--header__right--disabled {
    color: $disabled-02;
    cursor: not-allowed;
    display: flex;
  }

  .vehicle-appointment--header__right--disabled > svg {
    color: $disabled-02;
    margin-left: $spacing-02;
  }

  .vehicle-appointment--header__right--disabled path {
    color: $disabled-02;
    fill: $disabled-02;
  }

  .vehicle-appointment--header__right--hidden {
    display: none;
  }

  .appointment {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: $spacing-05;
    margin-top: $spacing-05;
  }

  .appointment--date,
  .appointment--time {
    background: $ui-01;
    padding: $spacing-06;
    width: 50%;
  }

  .appointment--time__hidden {
    display: none;
  }

  .appointment--date__alone {
    background: $ui-01;
    padding: $spacing-06;
    width: 100%;
  }

  .appointment--date {
    margin-right: $spacing-02;
  }

  .appointment--date__title,
  .appointment--time__title {
    color: $text-02;
  }

  .appointment--info {
    display: grid;
    grid-template-columns: 9.375rem auto;
  }

  .appointment--info div {
    border-bottom: 1px solid #e0e0e0;
    padding: $spacing-03 $spacing-05;
  }

  .appointment--tasks {
    margin-top: $spacing-09;
  }

  .appointment--tasks__title {
    color: $text-01;
    margin-bottom: $spacing-05;
  }

  .appointment--tasks li {
    background-color: $field-01;
    margin-bottom: $spacing-02;
    padding: $spacing-04 $spacing-07;
  }

  .appointment--tasks li::before {
    color: $interactive-01;
    content: '\2022';
    display: inline-block;
    font-weight: bold;
    margin-left: -1rem;
    width: 1rem;
  }

  .bx--date-picker__input {
    background: none;
    border-bottom: 0;
    max-width: 5rem;
    min-width: 5rem;
    padding: 0;
    width: 5rem;
  }

  .bx--label {
    margin-bottom: 0;
  }

  .bx--date-picker__icon {
    right: 3rem;
  }

  .bx--dropdown {
    background: none;
    border-bottom: 0;
    width: 8rem;
  }

  .bx--list-box__menu-icon {
    right: 50%;
  }

  .bx--modal-content {
    overflow: visible;
  }

  .bx--list-box--expanded .bx--list-box__menu {
    max-height: 9rem;
  }

  .pick-up-appointment__subtitle {
    margin-bottom: $spacing-06;
  }

  .pick-up__appointment {
    margin-top: $spacing-07;
  }

  .bx--dropdown .bx--list-box__field {
    padding: 0;
  }

  .bx--list-box__field:focus {
    outline: 0;
  }

  .tasklistMessage {
    padding-top: 3rem;
  }

  .tasklistMessage .text {
    float: left;
    font-size: 15px;
    font-weight: 700;
  }

  .tasklistMessage .bx--loading {
    height: 2.5rem;
    position: relative;
    top: -15px;
    width: 2.5rem;
  }

  .tasklistMessage .vehicle-details__loading {
    display: inline-block;
    width: 20px;
  }

  .custom-checkbox {
    cursor: pointer;
    display: inline-block;
    margin-right: $spacing-03;
    vertical-align: top;
  }
  .checkboxDisable {
    pointer-events: none;
  }

  .checkboxDisable .custom-checkbox {
    cursor: initial;
    display: none;
  }

  .checkboxDisable .custom-checkbox::before {
    background-color: transparent;
    content: '';
    height: 25px;
    pointer-events: none;
    position: absolute;
    width: 25px;
  }

  .checkboxDisable svg {
    color: #e1dcdc;
  }
}
